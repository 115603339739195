<template>
  <!-- 详情页 -->
  <div style="height: auto !important;">
    <div class="topbar_topbar__fiftH">
      <div class="topbar_navbar__B5d3V topbar_short__nVIS7"><a title="打开首页" href="/">
          <div class="topbar_home_logo__fpnFe">
            <!-- <img alt="logo" src="@/assets/images/logo.png"> -->
            <div class="topbar_site_name__v3LY4">{{ $store.state.sysName }}</div>
          </div>
        </a>
        <div class="topbar_home_input__xBlxh">
          <input type="text" placeholder="书名、作者" v-model="searchInput" @keyup.enter="keyupSubmit()">
        </div>
      </div>
    </div>
    <div class="detail_book_detail_view__w8t0D" style="height: auto !important; min-height: 0px !important;">
      <div class="detail_book_detail_container__R1xih" style="height: auto !important; min-height: 0px !important;">
        <div class="detail_img_and_info__9ZEdm"><a target="_blank" class="detail_img_wrapper_link__fbAAY" title="点击看大图"
            href="https://file2.book123.info:2053/covers/9787570214488.jpg">
            <div class="detail_img_wrapper__WfTUB">
              <!-- <img :src="require('@/assets/images/1.jpg')" /> -->
              <img fetchpriority="low" :src="bookInfo.data.book_image" :onerror="imgOnerror">
            </div>
          </a>
          <div class="detail_book_info__Mv_YW">
            <h1 class="detail_title__wYqGY">{{ bookInfo.data.title }}</h1>
            <p class="detail_author__V_EVp"><span class="detail_underlineLink__YvFoz">{{ bookInfo.data.author }}</span></p>
            <div class="detail_other_info_item__vd_rr">
              <p class="detail_label__ZwAV2">出版社</p>
              <p class="detail_value__kyJQA"><a class="detail_underlineLink__YvFoz"
                  href="/list?publisher=长江文艺出版社">长江文艺出版社</a></p>
            </div>
            <div class="detail_other_info_item__vd_rr">
              <p class="detail_label__ZwAV2">出版时间</p>
              <p class="detail_value__kyJQA">{{ bookInfo.data.publication_date }}</p>
            </div>
            <div class="detail_other_info_item__vd_rr">
              <p class="detail_label__ZwAV2">文件格式</p>
              <p class="detail_value__kyJQA">{{ bookInfo.data.file_format }}</p>
            </div>
            <div class="detail_other_info_item__vd_rr">
              <p class="detail_label__ZwAV2">文件大小</p>
              <p class="detail_value__kyJQA">{{ bookInfo.data.file_size }}</p>
            </div>
            <div class="detail_other_info_item__vd_rr">
              <p class="detail_label__ZwAV2">评分</p>
              <div class="detail_starts__dkF9x" :title="bookInfo.data.rating+'分'">
                <div class="detail_rote_starts__LzaGy" :style="'width:'+Math.round(bookInfo.data.rating*10)+'%;'">★★★★★</div>
              </div>
              <p class="detail_value__kyJQA" style="margin-left: 10px; font-size: 14px;">{{ bookInfo.data.rating }}分</p>
            </div>
          </div>
        </div>
        <div class="detail_book_section__Vh5EA">
          <div class="detail_section_title__qRgzS">内容简介</div>
          <div class="detail_section_detail__CIEGT">{{ bookInfo.data.book_introduction }}</div>
        </div>
        <div class="detail_book_section__Vh5EA">
          <div class="detail_section_title__qRgzS">目录</div>
          <div class="detail_section_detail__CIEGT">{{ bookInfo.data.book_path }}</div>
        </div>
        <div class="detail_book_section__Vh5EA">
          <div class="detail_section_title__qRgzS">下载地址</div>
          <div class="detail_btns_wrapper__310mw">
            <a class="detail_download_btn__v7b7k" :href="bookInfo.data.download">
              <span class="detail_download_text__IoHnu">直接下载</span>
              <span class="detail_download_icon__yJnzB">
                <svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0h24v24H0z" fill="none"></path>
                  <path
                    d="M1 14.5a6.496 6.496 0 0 1 3.064-5.519 8.001 8.001 0 0 1 15.872 0 6.5 6.5 0 0 1-2.936 12L7 21c-3.356-.274-6-3.078-6-6.5zm15.848 4.487a4.5 4.5 0 0 0 2.03-8.309l-.807-.503-.12-.942a6.001 6.001 0 0 0-11.903 0l-.12.942-.805.503a4.5 4.5 0 0 0 2.029 8.309l.173.013h9.35l.173-.013zM13 12h3l-4 5-4-5h3V8h2v4z">
                  </path>
                </svg>
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useData } from "@/utils/hook";
import { getInfo } from "@/utils/api"
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
const store = useStore();
const route = useRoute();  // 获取路由信息
const router = useRouter();  // 操作路由

onMounted(() => {
  getBookInfo()
})

// 图片加载失败的默认图
const INGSRC = require('@/assets/images/default.jpg')
const imgOnerror = e => {
  let img = e.srcElement
  img.src = INGSRC
  img.onerror = null
}
const [bookInfo, setBookInfo] = useData({})
const getBookInfo = async () => {
  const data = await getInfo(route.query.id)
  // console.log('data=',data.data[0])
  setBookInfo(data.data[0])
}
// 搜索
const searchInput = ref('')
function keyupSubmit() {
  store.commit('setSearch',searchInput.value)
  router.push({
    path: "/list",
    query: {
      searchInfo: searchInput.value
    }
  });
}
</script>

<style lang="scss" scoped>
.topbar_topbar__fiftH {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 80;
  font-size: 16px;
  height: 4.5em;
  -webkit-backdrop-filter: blur(1.25em);
  backdrop-filter: blur(1.25em);
  border-bottom: 1px solid #ebedf1;
  background-color: #fff;
  .topbar_navbar__B5d3V {
    max-width: 1280px;
    padding-left: 25px;
    max-width: 1120px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    height: 100%;
    .topbar_home_logo__fpnFe {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.25em;
      .topbar_site_name__v3LY4 {
        font-family: "SourceHanSerifCN-Bold";
        color: #000;
        font-size: 20px;
        font-weight: 400
      }
      img { width: 2.4em; height: 2.4em; margin-right: .625em; transition: transform .5s }
      &:hover img { transform: rotate(180deg) scale(.9) }
    }
    .topbar_home_input__xBlxh {
      position: relative;
      input {
        height: 2.25em;
        padding: 0 2.25em;
        margin: auto;
        border-radius: 1.25em;
        font-size: .875em;
        background-size: 100%;
        background-repeat: no-repeat;
        background-color: rgba(246, 246, 249, .1);
        background-size: 1em 1em;
        background-position: .875em .625em;
        color: #c1c8d2;
        width: 100%;
        max-width: 25em;
        background-color: #f6f7f9;
        background-image: url(@/assets/images/icon_search.png);
        box-sizing: border-box;
        color: #000;
        &:focus {
          outline: none
        }
      }
    }
  }
}

@media(min-width:1439px) {
  .topbar_topbar__fiftH .topbar_navbar__B5d3V {
    max-width: 1440px;
    &.topbar_short__nVIS7 { max-width: 1280px }
    .topbar_home_input__xBlxh input { width: 30em }
  }
}

@media(min-width:1270px)and (max-width:1439px) {
  .topbar_topbar__fiftH .topbar_navbar__B5d3V,
  .topbar_topbar__fiftH .topbar_navbar__B5d3V.topbar_short__nVIS7 {
    max-width: 1280px
  }

  .topbar_topbar__fiftH .topbar_navbar__B5d3V .topbar_home_input__xBlxh input {
    width: 30em
  }
}

@media(max-width:480px) {
  .topbar_topbar__fiftH .topbar_navbar__B5d3V {
    justify-content: baseline
  }

  .topbar_topbar__fiftH .topbar_navbar__B5d3V .topbar_home_logo__fpnFe .topbar_site_name__v3LY4 {
    display: none
  }

  .topbar_topbar__fiftH .topbar_navbar__B5d3V .topbar_home_input__xBlxh {
    width: 100%
  }

  .topbar_topbar__fiftH .topbar_navbar__B5d3V .topbar_home_input__xBlxh input {
    max-width: 100%
  }
}

.detail_book_detail_view__w8t0D {
  font-size: 16px;
  padding-top: 4.5em;
  background-color: #f6f7f9;
  line-height: 1.5em;
  min-height: calc(100vh - 4.5em);
  padding-bottom: 30px
}

.detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih {
  background-color: #fff;
  max-width: 1120px;
  min-height: inherit;
  margin: 0 auto;
  padding: 30px 30px 10px;
  box-sizing: border-box;
  position: relative
}

.detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_closeWrapper__7Fj_j {
  position: absolute;
  right: 10%;
  top: 100px;
  padding-top: 20px;
  cursor: pointer;
  width: 60px;
  height: 60px
}

.detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_closeWrapper__7Fj_j .detail_close__Fft1D {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 5px;
  background: #666;
  transform: rotate(45deg)
}

.detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_closeWrapper__7Fj_j .detail_close__Fft1D:after {
  position: relative;
  content: "";
  display: block;
  width: 60px;
  height: 5px;
  background: #666;
  transform: rotate(-90deg)
}

.detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_underlineLink__YvFoz {
  cursor: pointer
}

.detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_underlineLink__YvFoz:hover {
  text-decoration: underline
}

.detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_img_and_info__9ZEdm {
  position: relative;
  display: flex;
  padding-bottom: 2.5em;
  margin-bottom: 1.25em;
  border-bottom: 1px solid #f8f8f8
}

.detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_img_and_info__9ZEdm .detail_img_wrapper_link__fbAAY {
  width: 192px
}

.detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_img_and_info__9ZEdm .detail_img_wrapper__WfTUB {
  max-width: 192px;
  height: 288px;
  position: relative;
  align-self: center
}

.detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_img_and_info__9ZEdm .detail_img_wrapper__WfTUB img {
  vertical-align: top;
  width: 100%;
  height: 100%;
  background-color: #d8d8d8;
  -o-object-fit: cover;
  object-fit: cover
}

.detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_img_and_info__9ZEdm .detail_img_wrapper__WfTUB span {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(90deg, hsla(0, 0%, 63%, .25), rgba(21, 21, 20, .1) 1%, hsla(0, 0%, 100%, .15) 4%, hsla(0, 0%, 58%, .1) 8%, hsla(0, 0%, 89%, 0) 57%, hsla(0, 7%, 86%, .03) 91%, hsla(0, 7%, 86%, .05) 98%, hsla(0, 0%, 100%, .1));
  box-shadow: inset 0 0 0 0 rgba(0, 0, 0, .1), 0 0 8px 0 rgba(232, 237, 250, .6), 0 2px 4px 0 rgba(232, 237, 250, .5)
}

.detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_img_and_info__9ZEdm .detail_book_info__Mv_YW {
  margin-left: 2.5em
}

.detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_img_and_info__9ZEdm .detail_book_info__Mv_YW .detail_title__wYqGY {
  margin-top: .25em;
  color: #212832;
  font-family: "SourceHanSerifCN-Bold", PingFang SC, -apple-system, SF UI Text, Lucida Grande, STheiti, Microsoft YaHei, sans-serif;
  font-size: 1.5em;
  width: 100%;
  line-height: 1.2;
  font-weight: 500
}

.detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_img_and_info__9ZEdm .detail_book_info__Mv_YW .detail_origin_name__YQW_d {
  font-size: .75em
}

.detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_img_and_info__9ZEdm .detail_book_info__Mv_YW .detail_author__V_EVp {
  font-size: 1.125em;
  color: #b60000;
  font-family: "SourceHanSerifCN-Bold";
  margin: .625em 0 1em
}

.detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_img_and_info__9ZEdm .detail_book_info__Mv_YW .detail_author__V_EVp .detail_underlineLink__YvFoz {
  color: #b60000
}

.detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_img_and_info__9ZEdm .detail_book_info__Mv_YW .detail_other_info_item__vd_rr {
  display: flex;
  margin-top: .375em
}

.detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_img_and_info__9ZEdm .detail_book_info__Mv_YW .detail_other_info_item__vd_rr .detail_label__ZwAV2 {
  color: #858e97;
  width: 6.25em;
  font-size: .875em
}

.detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_img_and_info__9ZEdm .detail_book_info__Mv_YW .detail_other_info_item__vd_rr .detail_value__kyJQA {
  font-size: 1em;
  color: #666
}

.detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_img_and_info__9ZEdm .detail_book_info__Mv_YW .detail_other_info_item__vd_rr .detail_value__kyJQA a {
  color: #666
}

.detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_img_and_info__9ZEdm .detail_book_info__Mv_YW .detail_other_info_item__vd_rr .detail_value__kyJQA .detail_tag__q_o6Q {
  display: inline-block;
  margin-right: 5px;
  color: #37a;
  font-size: 14px
}

.detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_img_and_info__9ZEdm .detail_ads__OO3mn {
  position: absolute;
  width: 300px;
  height: 280px;
  right: 0;
  top: 0
}

.detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_book_section__Vh5EA {
  margin-top: 2em;
  font-size: 1em;
  color: #586068;
  line-height: 2em;
  padding-bottom: .8em;
  position: relative
}

.detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_book_section__Vh5EA .detail_section_title__qRgzS {
  font-size: 22px;
  font-family: "SourceHanSerifCN-Bold";
  color: #b60000;
  margin-bottom: 20px
}

.detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_book_section__Vh5EA .detail_book_error__z8ClU {
  position: absolute;
  top: 0;
  right: 10px;
  cursor: pointer
}

.detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_book_section__Vh5EA .detail_section_detail__CIEGT {
  white-space: pre-line
}

.detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_book_section__Vh5EA:not(:last-child) {
  border-bottom: 1px solid #f8f8f8
}

.detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_book_section__Vh5EA .detail_comment__xzMux {
  padding: 10px 0
}

.detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_book_section__Vh5EA .detail_comment__xzMux:not(:last-child) {
  border-bottom: 1px solid #f8f8f8
}

.detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_book_section__Vh5EA .detail_moreLink__5INJW {
  cursor: pointer;
  color: #37a
}

.detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_book_section__Vh5EA .detail_moreLink__5INJW:hover {
  text-decoration: underline
}

.detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_book_section__Vh5EA .detail_releated_books__YvEoC {
  display: flex;
  list-style: none;
  flex-wrap: wrap
}

.detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_book_section__Vh5EA .detail_releated_books__YvEoC li {
  display: flex;
  flex-direction: column;
  margin-right: 25px;
  margin-bottom: 10px;
  overflow: hidden
}

.detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_book_section__Vh5EA .detail_releated_books__YvEoC li a {
  width: 125px;
  line-height: 20px;
  display: flex;
  flex-direction: column
}

.detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_book_section__Vh5EA .detail_releated_books__YvEoC li a .detail_book_info__Mv_YW {
  text-align: center
}

.detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_book_section__Vh5EA .detail_releated_books__YvEoC li a .detail_book_title__XpuWb {
  display: -webkit-box;
  font-size: 14px;
  overflow: hidden;
  line-height: 20px;
  text-align: center;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #586068
}

.detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_book_section__Vh5EA .detail_releated_books__YvEoC li a .detail_book_author__AeY4i {
  display: none;
  color: #586068
}

.detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_book_section__Vh5EA .detail_releated_books__YvEoC li .detail_img_wrapper__WfTUB {
  width: 120px;
  height: 170px;
  text-align: center;
  padding-bottom: 10px
}

.detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_book_section__Vh5EA .detail_releated_books__YvEoC li .detail_img_wrapper__WfTUB img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover
}

.detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_releated_books_wrapper__Ttyqb {
  position: relative
}

.detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_pnl_ads__kZbtC {
  margin: 30px 0
}

.detail_book_detail_view__w8t0D .detail_starts__dkF9x {
  position: relative;
  font-size: 1em;
  width: 5.3125em;
  letter-spacing: 1px
}

.detail_book_detail_view__w8t0D .detail_starts__dkF9x:before {
  content: "★★★★★";
  position: absolute;
  color: #dbdbdb
}

.detail_book_detail_view__w8t0D .detail_starts__dkF9x .detail_rote_starts__LzaGy {
  position: absolute;
  color: #f8ab2e;
  overflow: hidden
}

.detail_book_detail_view__w8t0D .detail_btns_wrapper__310mw {
  display: flex;
  align-items: center;
  margin-top: 1.25em
}

.detail_book_detail_view__w8t0D .detail_btns_wrapper__310mw .detail_download_btn__v7b7k {
  position: relative;
  margin-right: 1.25em;
  font-size: 1em;
  width: 9.375em;
  height: 3.125em;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border: none;
  border-radius: .3125em;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, .15);
  background: #b60000;
  transition: .2s;
  box-sizing: border-box
}

.detail_book_detail_view__w8t0D .detail_btns_wrapper__310mw .detail_download_btn__v7b7k span {
  transition: .2s
}

.detail_book_detail_view__w8t0D .detail_btns_wrapper__310mw .detail_download_btn__v7b7k .detail_download_text__IoHnu {
  transform: translateX(1.25em);
  color: #fff
}

.detail_book_detail_view__w8t0D .detail_btns_wrapper__310mw .detail_download_btn__v7b7k .detail_download_icon__yJnzB {
  position: absolute;
  border-left: 1px solid hsla(0, 0%, 100%, .6);
  right: 0;
  height: 2.5em;
  width: 2.5em;
  display: flex;
  align-items: center;
  justify-content: center
}

.detail_book_detail_view__w8t0D .detail_btns_wrapper__310mw .detail_download_btn__v7b7k svg {
  width: 1.25em;
  fill: #eee
}

.detail_book_detail_view__w8t0D .detail_btns_wrapper__310mw .detail_download_btn__v7b7k:hover {
  background: #b60000
}

.detail_book_detail_view__w8t0D .detail_btns_wrapper__310mw .detail_download_btn__v7b7k:hover .detail_download_text__IoHnu {
  color: transparent
}

.detail_book_detail_view__w8t0D .detail_btns_wrapper__310mw .detail_download_btn__v7b7k:hover .detail_download_icon__yJnzB {
  width: 9.375em;
  border-left: none;
  transform: translateX(0)
}

.detail_book_detail_view__w8t0D .detail_btns_wrapper__310mw .detail_download_btn__v7b7k:focus {
  outline: none
}

.detail_book_detail_view__w8t0D .detail_btns_wrapper__310mw .detail_download_btn__v7b7k:active {
  transform: scale(.95)
}

.detail_book_detail_view__w8t0D .detail_btns_wrapper__310mw .detail_send_btn__in1ac {
  margin-right: 1.25em;
  font-family: inherit;
  font-size: 1em;
  width: 9.625em;
  height: 3.125em;
  background: #4169e1;
  color: #fff;
  box-sizing: border-box;
  padding: .7em 1em .7em .9em;
  display: inline-flex;
  align-items: center;
  border: none;
  border-radius: .3125em;
  overflow: hidden;
  transition: all .2s;
  cursor: pointer
}

.detail_book_detail_view__w8t0D .detail_btns_wrapper__310mw .detail_send_btn__in1ac span {
  display: block;
  margin-left: .3em;
  transition: all .3s ease-in-out
}

.detail_book_detail_view__w8t0D .detail_btns_wrapper__310mw .detail_send_btn__in1ac svg {
  display: block;
  transform-origin: center center;
  transition: transform .3s ease-in-out
}

.detail_book_detail_view__w8t0D .detail_btns_wrapper__310mw .detail_send_btn__in1ac:hover .detail_svg_wrapper__INu7H {
  -webkit-animation: detail_fly-1__V4_64 .6s ease-in-out infinite alternate;
  animation: detail_fly-1__V4_64 .6s ease-in-out infinite alternate
}

.detail_book_detail_view__w8t0D .detail_btns_wrapper__310mw .detail_send_btn__in1ac:hover svg {
  transform: translateX(3em) rotate(45deg) scale(1.1)
}

.detail_book_detail_view__w8t0D .detail_btns_wrapper__310mw .detail_send_btn__in1ac:hover span {
  transform: translateX(10em)
}

.detail_book_detail_view__w8t0D .detail_btns_wrapper__310mw .detail_send_btn__in1ac:active {
  transform: scale(.95)
}

@-webkit-keyframes detail_fly-1__V4_64 {
  0% {
    transform: translateY(.1em)
  }

  to {
    transform: translateY(-.1em)
  }
}

@keyframes detail_fly-1__V4_64 {
  0% {
    transform: translateY(.1em)
  }

  to {
    transform: translateY(-.1em)
  }
}

.detail_book_detail_view__w8t0D .detail_downloadDesc__TKQAA {
  font-size: 12px;
  margin-top: 10px;
  color: #666
}

.detail_dialog__zxRx3 {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .5)
}

.detail_dialog__zxRx3 .detail_modal__o9lch {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 550px;
  height: 300px;
  background: #fff;
  z-index: 101;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
  border: 1px solid rgba(0, 0, 0, .2)
}

.detail_dialog__zxRx3 .detail_modal__o9lch .detail_header__KDFuD {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5
}

.detail_dialog__zxRx3 .detail_modal__o9lch .detail_header__KDFuD .detail_title__wYqGY {
  font-size: 18px;
  color: #333
}

.detail_dialog__zxRx3 .detail_modal__o9lch .detail_header__KDFuD .detail_close__Fft1D {
  float: right;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: .2;
  margin-top: -2px;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none
}

.detail_dialog__zxRx3 .detail_modal__o9lch .detail_body__1TLws {
  position: relative;
  padding: 15px
}

.detail_dialog__zxRx3 .detail_modal__o9lch .detail_body__1TLws td {
  padding: 0 10px 15px;
  vertical-align: top
}

.detail_dialog__zxRx3 .detail_modal__o9lch .detail_body__1TLws td.detail_key__3Kb3S {
  color: #666
}

.detail_dialog__zxRx3 .detail_modal__o9lch .detail_body__1TLws select {
  height: 34px;
  padding: 5px;
  width: 200px
}

.detail_dialog__zxRx3 .detail_modal__o9lch .detail_body__1TLws textarea {
  width: 330px;
  height: 80px;
  border: 1px solid #ccc;
  padding: 6px;
  resize: none;
  outline: none
}

.detail_dialog__zxRx3 .detail_modal__o9lch .detail_body__1TLws button {
  width: 100px;
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid #ccc;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

@media(min-width:1270px) {
  .detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih {
    position: static;
    width: 1000px;
    margin-left: calc(50% - 650px);
    margin-top: 20px
  }

  .detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_book_section__Vh5EA .detail_releated_books__YvEoC {
    display: flex;
    list-style: none;
    flex-wrap: nowrap;
    flex-direction: column
  }

  .detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_book_section__Vh5EA .detail_releated_books__YvEoC li {
    margin-right: 10px
  }

  .detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_book_section__Vh5EA .detail_releated_books__YvEoC li a {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center
  }

  .detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_book_section__Vh5EA .detail_releated_books__YvEoC li a .detail_book_info__Mv_YW {
    margin-left: 20px;
    width: 200px;
    text-align: left
  }

  .detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_book_section__Vh5EA .detail_releated_books__YvEoC li a .detail_book_info__Mv_YW .detail_book_title__XpuWb {
    display: -webkit-box;
    font-size: 15px;
    font-weight: 500;
    color: #0d141e;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    width: calc(100% - 84px)
  }

  .detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_book_section__Vh5EA .detail_releated_books__YvEoC li a .detail_book_info__Mv_YW .detail_book_author__AeY4i {
    display: block;
    text-align: left;
    font-size: 14px;
    margin-top: 6px
  }

  .detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_book_section__Vh5EA .detail_releated_books__YvEoC li .detail_img_wrapper__WfTUB {
    width: 64px;
    height: 92px;
    padding-bottom: 10px;
    flex-shrink: 0
  }

  .detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_book_section__Vh5EA .detail_releated_books__YvEoC li .detail_img_wrapper__WfTUB img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
  }

  .detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_releated_books_wrapper__Ttyqb {
    position: absolute;
    width: 260px;
    right: calc(50% - 640px);
    top: 90px
  }

  .detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_releated_books_wrapper__Ttyqb .detail_pnl_ads_side__DIfMT {
    margin-top: 30px;
    min-height: 300px
  }
}

@media(max-width:768px) {
  .detail_book_detail_view__w8t0D {
    font-size: 12px
  }

  .detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih {
    padding: 1.25em 1.25em 0
  }

  .detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_closeWrapper__7Fj_j {
    position: absolute;
    right: 10%;
    top: 50px;
    padding-top: 20px;
    cursor: pointer;
    width: 30px;
    height: 30px
  }

  .detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_closeWrapper__7Fj_j .detail_close__Fft1D {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 3px;
    background: #666;
    transform: rotate(45deg)
  }

  .detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_closeWrapper__7Fj_j .detail_close__Fft1D:after {
    position: relative;
    content: "";
    display: block;
    width: 30px;
    height: 3px;
    background: #666;
    transform: rotate(-90deg)
  }

  .detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_img_and_info__9ZEdm {
    display: block
  }

  .detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_img_and_info__9ZEdm .detail_img_wrapper__WfTUB {
    margin: 1.25em auto
  }

  .detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_img_and_info__9ZEdm .detail_book_info__Mv_YW {
    margin-left: 0
  }

  .detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_book_error__z8ClU,
  .detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_img_and_info__9ZEdm .detail_ads__OO3mn {
    display: none
  }
}

@media(max-width:500px) {
  .detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_img_wrapper_link__fbAAY {
    width: auto
  }

  .detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_img_and_info__9ZEdm .detail_book_info__Mv_YW .detail_author__V_EVp {
    font-size: 14px
  }

  .detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_img_and_info__9ZEdm .detail_book_info__Mv_YW .detail_other_info_item__vd_rr .detail_label__ZwAV2 {
    font-size: 13px
  }

  .detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_book_section__Vh5EA,
  .detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_img_and_info__9ZEdm .detail_book_info__Mv_YW .detail_other_info_item__vd_rr .detail_value__kyJQA {
    font-size: 14px
  }

  .detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_book_section__Vh5EA .detail_releated_books__YvEoC {
    display: flex;
    list-style: none;
    flex-wrap: nowrap;
    flex-direction: column
  }

  .detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_book_section__Vh5EA .detail_releated_books__YvEoC li {
    margin-right: 10px
  }

  .detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_book_section__Vh5EA .detail_releated_books__YvEoC li a {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center
  }

  .detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_book_section__Vh5EA .detail_releated_books__YvEoC li a .detail_book_info__Mv_YW {
    margin-left: 20px;
    width: calc(100% - 68px)
  }

  .detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_book_section__Vh5EA .detail_releated_books__YvEoC li a .detail_book_info__Mv_YW .detail_book_title__XpuWb {
    display: block;
    font-size: 15px;
    font-weight: 500;
    color: #0d141e;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
    word-wrap: normal;
    text-align: left;
    width: 100%
  }

  .detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_book_section__Vh5EA .detail_releated_books__YvEoC li a .detail_book_info__Mv_YW .detail_book_author__AeY4i {
    display: block;
    text-align: left;
    font-size: 14px;
    margin-top: 6px
  }

  .detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_book_section__Vh5EA .detail_releated_books__YvEoC li .detail_img_wrapper__WfTUB {
    width: 64px;
    height: 92px;
    padding-bottom: 10px;
    flex-shrink: 0
  }

  .detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_book_section__Vh5EA .detail_releated_books__YvEoC li .detail_img_wrapper__WfTUB img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
  }

  .detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_book_section__Vh5EA .detail_book_error__z8ClU {
    display: none
  }

  .detail_book_detail_view__w8t0D .detail_book_detail_container__R1xih .detail_pnl_ads__kZbtC {
    max-height: 120px
  }
}
</style>
