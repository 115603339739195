import service from "../axios/axios";
import store from "../store"


// 获取最新的10条信息
export let getLately = async() => {
    return service({
        url: `/latest`,
        method: 'GET',
    })
}

// 获取详情
export let getInfo = async(id) => {
    return service({
        url: `/book/${id}`,
        method: 'GET',
    })
}

 // 搜索
 export let getSearchData = async(info,pageindex) => {  // 搜索
    return service({
        url: `/search?q=${info}${pageindex?'&page='+pageindex:''}`,
        method: 'GET'
    })
}