import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/main.css'
import './assets/iconfont/iconfont.css'
import service from "./axios/axios.js"

const app = createApp(App)
app.config.globalProperties.$axios = service
app.use(store).use(router).mount('#app')
