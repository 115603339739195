import { reactive } from "vue"

export let useData = (value) => {
    let initData = reactive({
        data:{}
    })
    initData.data= value;
    let setData = (newData) => {
        initData.data = JSON.parse(JSON.stringify(newData));
    }
    return [initData, setData]
}
