import { createStore } from 'vuex'

export default createStore({
  state: {
    sysName: '盏茶阅读',
    bookInfo: '', // 存储书籍信息用于修改页面标题
    searchDetails: '', // 存储搜索内容用于修改页面标题
  },
  getters: {
  },
  mutations: {
    setBook(state, payload) {
      state.bookInfo = payload
    },
    setSearch(state, payload) {
      state.searchDetails = payload
    }
  },
  actions: {
  },
  modules: {
  }
})
