<template>
  <!-- 搜索页 -->
  <div id="__next" data-reactroot="">
    <div class="topbar_topbar__fiftH">
      <div class="topbar_navbar__B5d3V"><a title="打开首页" href="/">
          <div class="topbar_home_logo__fpnFe">
            <!-- <img alt="logo" src="@/assets/images/logo.png"> -->
            <div class="topbar_site_name__v3LY4">{{ $store.state.sysName }}</div>
          </div>
        </a>
        <div class="topbar_home_input__xBlxh">
          <input type="text" placeholder="书名、作者" v-model="searchInput" @keyup.enter="keyupSubmit()">
        </div>
      </div>
    </div>
    <div class="list_container__9YjD2">
      <div class="list_pageContent__z_yzv">
        <div class="list_search_desc__klTjr" v-if="countSearch>0">我找到了 <span class="blue">{{ countSearch }}</span> 本书</div>
        <div class="list_search_desc__klTjr" v-if="showNone">
          没有我要的结果
        </div>
        <ul>
          <div v-for="m in bookList.data" :key="m.book_id" @click="detaisGo(m,m.book_id)">
            <li>
              <div class="list_bookItem__yQCTQ">
                <div class="list_bookCover__iEh_y">
                  <span class="" style="display:inline-block;width:108px;height:156px">
                    <img fetchpriority="low" :alt="m.title" :src="m.book_image" :onerror="imgOnerror">
                  </span>
                </div>
                <div class="list_itemInfo__I6VD2">
                  <p class="list_title__06nt2">{{ m.title }}</p>
                  <p class="list_nobook__l2RIw">{{ m.author }}</p>
                  <p class="list_rate__J7Ox1">评分 <!-- -->{{ m.rating }}分</p>
                  <p class="list_desc__kJj1Y">{{ m.book_introduction }}</p>
                </div>
              </div>
            </li>
          </div>
        </ul>
      </div>
    </div>
  </div>
  
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useData } from "@/utils/hook";
import { getSearchData } from "@/utils/api"
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
const store = useStore();
const route = useRoute();  // 获取路由信息
const router = useRouter();  // 操作路由

onMounted(() => {
  getList()
})
// 图片加载失败的默认图
const INGSRC = require('@/assets/images/default.jpg')
const imgOnerror = e => {
  let img = e.srcElement
  img.src = INGSRC
  img.onerror = null
}
// 搜索到的书籍
const countSearch = ref('')
const showNone = ref(false)
const [bookList, setBookList] = useData([])
const getList = async () => {
  const data = await getSearchData(searchInput.value)
  if(data.count==0) {
    showNone.value = true
  } else {
    countSearch.value = data.count
  }
  setBookList(data.data)
}
// 搜索
const searchInput = ref(route.query.searchInfo?route.query.searchInfo:'')
function keyupSubmit() {
  console.log('搜索内容：',searchInput.value)
  store.commit('setSearch',searchInput.value)
  router.push({
    path: "/list",
    query: {
      searchInfo: searchInput.value
    }
  });
  setBookList([])
  countSearch.value = ''
  showNone.value = false
  getList()
}
// 跳转详情页
function detaisGo(info,id) {
  store.commit('setBook',info)
  router.push({
    path: "/detail",
    query: {
      id: id
    }
  });
}
</script>

<style lang="scss" scoped>
.topbar_topbar__fiftH {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 80;
    font-size: 16px;
    height: 4.5em;
    -webkit-backdrop-filter: blur(1.25em);
    backdrop-filter: blur(1.25em);
    border-bottom: 1px solid #ebedf1;
    background-color: #fff
}

.topbar_topbar__fiftH .topbar_navbar__B5d3V {
    max-width: 1280px;
    padding-left: 25px;
    max-width: 1120px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    height: 100%
}

.topbar_topbar__fiftH .topbar_navbar__B5d3V .topbar_home_logo__fpnFe {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.25em
}

.topbar_topbar__fiftH .topbar_navbar__B5d3V .topbar_home_logo__fpnFe .topbar_site_name__v3LY4 {
    font-family: "SourceHanSerifCN-Bold";
    color: #000;
    font-size: 20px;
    font-weight: 400
}

.topbar_topbar__fiftH .topbar_navbar__B5d3V .topbar_home_logo__fpnFe img {
    width: 2.4em;
    height: 2.4em;
    margin-right: .625em;
    transition: transform .5s
}

.topbar_topbar__fiftH .topbar_navbar__B5d3V .topbar_home_logo__fpnFe:hover img {
    transform: rotate(180deg) scale(.9)
}

.topbar_topbar__fiftH .topbar_navbar__B5d3V .topbar_home_input__xBlxh {
    position: relative
}

.topbar_topbar__fiftH .topbar_navbar__B5d3V .topbar_home_input__xBlxh input {
    height: 2.25em;
    padding: 0 2.25em;
    margin: auto;
    border-radius: 1.25em;
    font-size: .875em;
    background-size: 100%;
    background-repeat: no-repeat;
    background-color: rgba(246,246,249,.1);
    background-size: 1em 1em;
    background-position: .875em .625em;
    color: #c1c8d2;
    width: 100%;
    max-width: 25em;
    background-color: #f6f7f9;
    background-image: url(../assets/images/icon_search.png);
    box-sizing: border-box;
    color: #000
}

.topbar_topbar__fiftH .topbar_navbar__B5d3V .topbar_home_input__xBlxh input:focus {
    outline: none
}

@media(min-width: 1439px) {
    .topbar_topbar__fiftH .topbar_navbar__B5d3V {
        max-width:1440px
    }

    .topbar_topbar__fiftH .topbar_navbar__B5d3V.topbar_short__nVIS7 {
        max-width: 1280px
    }

    .topbar_topbar__fiftH .topbar_navbar__B5d3V .topbar_home_input__xBlxh input {
        width: 30em
    }
}

@media(min-width: 1270px)and (max-width:1439px) {
    .topbar_topbar__fiftH .topbar_navbar__B5d3V,.topbar_topbar__fiftH .topbar_navbar__B5d3V.topbar_short__nVIS7 {
        max-width:1280px
    }

    .topbar_topbar__fiftH .topbar_navbar__B5d3V .topbar_home_input__xBlxh input {
        width: 30em
    }
}

@media(max-width: 480px) {
    .topbar_topbar__fiftH .topbar_navbar__B5d3V {
        justify-content:baseline
    }

    .topbar_topbar__fiftH .topbar_navbar__B5d3V .topbar_home_logo__fpnFe .topbar_site_name__v3LY4 {
        display: none
    }

    .topbar_topbar__fiftH .topbar_navbar__B5d3V .topbar_home_input__xBlxh {
        width: 100%
    }

    .topbar_topbar__fiftH .topbar_navbar__B5d3V .topbar_home_input__xBlxh input {
        max-width: 100%
    }
}

.list_container__9YjD2 {
    margin: auto;
    max-width: 1440px;
    padding-left: 30px
}

.list_container__9YjD2 .list_pageContent__z_yzv {
    // margin-left: 230px;
    margin-top: 90px
}

.list_container__9YjD2 .list_pageContent__z_yzv .list_pnl_filters___gBqc {
    padding: 10px 0 20px;
    border-bottom: 1px solid #eee;
    margin-bottom: 10px
}

.list_container__9YjD2 .list_pageContent__z_yzv .list_pnl_filters___gBqc li {
    display: inline-block;
    width: 80px;
    font-size: 17px;
    color: #5d646e;
    font-weight: 700
}

.list_container__9YjD2 .list_pageContent__z_yzv .list_pnl_filters___gBqc li:hover {
    background: none;
    color: #000
}

.list_container__9YjD2 .list_pageContent__z_yzv .list_pnl_filters___gBqc li:hover a {
    color: #000
}

.list_container__9YjD2 .list_pageContent__z_yzv .list_pnl_filters___gBqc li.list_selected__legMg,.list_container__9YjD2 .list_pageContent__z_yzv .list_pnl_filters___gBqc li.list_selected__legMg a {
    color: #b60000
}

.list_container__9YjD2 .list_pageContent__z_yzv .list_pnl_filters___gBqc li a {
    color: #5d646e
}

.list_container__9YjD2 .list_pageContent__z_yzv .list_search_desc__klTjr {
    color: #666;
    font-size: 14px;
    padding: 5px 0 10px 15px;
    .blue { color: #2878ff }
}

.list_container__9YjD2 .list_pageContent__z_yzv .list_search_desc__klTjr a {
    color: #2878ff
}

.list_container__9YjD2 .list_pageContent__z_yzv li {
    padding: 0 20px
}

.list_container__9YjD2 .list_pageContent__z_yzv li:hover {
    cursor: pointer;
    background: linear-gradient(90deg,#edeeef,#fff)
}

.list_container__9YjD2 .list_pageContent__z_yzv li.list_bookAds__f3Yvh:hover {
    background: none
}

.list_container__9YjD2 .list_pageContent__z_yzv .list_bookItem__yQCTQ {
    display: flex;
    padding: 20px 0;
    overflow: hidden;
    align-items: center
}

.list_container__9YjD2 .list_pageContent__z_yzv .list_bookItem__yQCTQ .list_bookCover__iEh_y {
    flex-shrink: 0;
    width: 108px!important;
    height: 156px!important;
    box-shadow: 0 2px 16px rgba(0,0,0,.08);
    background: #d8d8d8;
    position: relative
}

.list_container__9YjD2 .list_pageContent__z_yzv .list_bookItem__yQCTQ .list_bookCover__iEh_y img {
    vertical-align: top;
    width: 100%;
    height: 100%;
    background-color: #d8d8d8;
    -o-object-fit: cover;
    object-fit: cover
}

.list_container__9YjD2 .list_pageContent__z_yzv .list_bookItem__yQCTQ .list_itemInfo__I6VD2 {
    flex: 1 1;
    padding: 0 0 0 32px
}

.list_container__9YjD2 .list_pageContent__z_yzv .list_bookItem__yQCTQ .list_itemInfo__I6VD2 .list_title__06nt2 {
    color: #0d141e;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-text-size-adjust: none;
    -webkit-line-clamp: 2;
    font-size: 18px;
    font-family: "SourceHanSerifCN-Bold",PingFang SC,-apple-system,SF UI Text,Lucida Grande,STheiti,Microsoft YaHei,sans-serif;
    line-height: 26px;
    overflow: hidden;
    height: auto;
    max-height: 52px
}

.list_container__9YjD2 .list_pageContent__z_yzv .list_bookItem__yQCTQ .list_itemInfo__I6VD2 .list_author__lPwpl,.list_container__9YjD2 .list_pageContent__z_yzv .list_bookItem__yQCTQ .list_itemInfo__I6VD2 .list_nobook__l2RIw {
    color: #5d646e;
    margin-top: 10px;
    font-size: 14px;
    line-height: 21px;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    -webkit-text-size-adjust: none;
    box-orient: vertical;
    height: auto;
    max-height: 21px
}

.list_container__9YjD2 .list_pageContent__z_yzv .list_bookItem__yQCTQ .list_itemInfo__I6VD2 .list_nobook__l2RIw {
    color: #b60000
}

.list_container__9YjD2 .list_pageContent__z_yzv .list_bookItem__yQCTQ .list_itemInfo__I6VD2 .list_rate__J7Ox1 {
    color: #858c96;
    display: flex;
    align-items: center;
    margin-top: 8px;
    font-family: DIN-Medium,PingFang SC,-apple-system,SF UI Text,Lucida Grande,STheiti,Microsoft YaHei,sans-serif;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
    word-wrap: normal;
    font-size: 12px
}

.list_container__9YjD2 .list_pageContent__z_yzv .list_bookItem__yQCTQ .list_itemInfo__I6VD2 .list_desc__kJj1Y {
    margin-top: 8px;
    font-size: 14px;
    color: #858c96;
    line-height: 22px;
    overflow: hidden;
    height: 44px;
    display: -webkit-box;
    display: -moz-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -moz-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    -webkit-text-size-adjust: none;
    box-orient: vertical;
    height: auto;
    max-height: 44px
}

.list_container__9YjD2 .list_pageContent__z_yzv .list_showMore__pT7ld {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    color: #999
}

.list_container__9YjD2 .list_pageContent__z_yzv .list_noBook__6zSwc {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 100px;
    font-size: 24px;
    font-family: "SourceHanSerifCN-Bold";
    color: #666
}

.list_container__9YjD2 .list_pageContent__z_yzv .list_isLoading__nhrtq {
    margin: 200px auto;
    width: 400px;
    text-align: center;
    font-size: 30px;
    font-family: "SourceHanSerifCN-Bold";
    color: #666
}

@media(min-width: 1270px)and (max-width:1439px) {
    .list_container__9YjD2 .list_pageContent__z_yzv {
        // width:calc(100% - 600px)
    }
}

@media(min-width: 1439px) {
    .list_container__9YjD2 .list_pageContent__z_yzv {
        // width:calc(100% - 600px)
    }
}

@media(max-width: 680px) {
    .list_container__9YjD2 .list_pageContent__z_yzv {
        margin-left: -20px;
        margin-top: 75px
    }

    .list_container__9YjD2 .list_pageContent__z_yzv .list_search_desc__klTjr {
        padding-top: 20px
    }

    .list_container__9YjD2 .list_pageContent__z_yzv .list_pnl_filters___gBqc {
        padding-bottom: 15px
    }

    .list_container__9YjD2 .list_pageContent__z_yzv .list_bookItem__yQCTQ .list_itemInfo__I6VD2 .list_author__lPwpl,.list_container__9YjD2 .list_pageContent__z_yzv .list_bookItem__yQCTQ .list_itemInfo__I6VD2 .list_rate__J7Ox1 {
        font-size: 14px
    }
}

@media(max-width: 460px) {
    .list_container__9YjD2 .list_pageContent__z_yzv .list_bookItem__yQCTQ .list_itemInfo__I6VD2 .list_desc__kJj1Y {
        display:none
    }
}

</style>
