<template>
  <nav>
    <router-link to="/"></router-link>
  </nav>
  <router-view/>
</template>

<style lang="scss">
#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>