import axios from "axios";
import store from "../store/index"
import Router from '../router/index'
// import { ElMessage } from "element-plus";

// 使用自定义配置新建一个实例
const service = axios.create({
    timeout: 6000
})
// 添加请求拦截器
service.interceptors.request.use(
    config => {
        // 在发送请求之前给请求头添加token
        // let _str = "Bearer " + store.state.userToken
        // config.headers['Authorization'] = _str
        
        config.url = PLATFORMOPEHOST.sysPath + config.url
        return config
    },
    error => {
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);
// 添加响应拦截器
service.interceptors.response.use(
    response => {
        // 2xx 范围内的状态码都会触发该函数，对响应数据做点什么
        return response.data;
    },
    error => {
        // 超出 2xx 范围的状态码都会触发该函数，对响应错误做点什么
        if(error.response.status == 401) { // 请求接口时token过期的情况
            // 1. 移除token并跳转登录页，登录成功后重定向到之前的页面
            let path = store.state.breadcrumb.children?store.state.breadcrumb.children.path: ''
            setTimeout(() => {
                store.commit('clearUserToken') // 移除token
                Router.push({
                    path: `/login?redirect=${path}`
                })
                // 2. 弹窗提示
                // ElMessage.error('登录失效，请重新登录')
                alert('登录失效，请重新登录')
            }, 2000);
        } else {
            // ElMessage.error(error.code=='ERR_BAD_REQUEST'?error.response.data.error_description:'服务异常')
            console.log(error.code=='ERR_BAD_REQUEST'?error.response.data.error_description:'服务异常')
        }
        return Promise.reject(error);
    }
)

export default service