<template>
  <div>
    <div class="header">
      <div class="logo">
        <!-- <img alt="logo" src="https://www.book123.info/web_images/logo.png"> -->
        <h1>{{ $store.state.sysName }}</h1>
      </div>
      <div class="search-input">
        <input type="text" placeholder="书名、作者" v-model="searchInput" @keyup.enter="keyupSubmit()">
      </div>
    </div>
    <div class="home_home__bzUT9">
      <div class="home_book-list-by-tag__ucAzj">
        <div class="home_tag__rYqur">最近更新</div>
        <div class="home_book-list__u1YId">
          <view class="home_book-container__yLSwQ" v-for="m in latelyList.data" :key="m.book_id" @click="detaisGo(m,m.book_id)">
            <div class="home_book-item__nBNpL">
              <div class="home_img-wrapper__g5UOf">
                <img fetchpriority="low" :alt="m.title" :src="m.book_image" :onerror="imgOnerror">
              </div>
              <div class="home_book-info__lSdFf">
                <p class="home_title__Q9lyE">{{ m.title }}</p>
                <p class="home_author__e13yD">{{ m.author }}</p>
              </div>
            </div>
          </view>
        </div>
        <!-- <div style="display:flex; justify-content: center;"><a class="home_to-more__v21dN" href="/list/?tag=最近更新">查看全部 &gt;</a></div> -->
      </div>
    </div>
    <footer>如有任何问题，可联系 23442435@qq.com</footer>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useData } from "@/utils/hook";
import { getLately } from "@/utils/api"
import { useStore } from "vuex";
import { useRouter } from "vue-router";
const store = useStore();
const router = useRouter(); // 操作路由

onMounted(() => {
  getLatelyList()
})
// 搜索
const searchInput = ref('')
function keyupSubmit() {
  // console.log('按了回车键')
  store.commit('setSearch',searchInput.value)
  router.push({
    path: "/list",
    query: {
      searchInfo: searchInput.value
    }
  });
}
// 图片加载失败的默认图
const INGSRC = require('@/assets/images/default.jpg')
const imgOnerror = e => {
  let img = e.srcElement
  img.src = INGSRC
  img.onerror = null
}
// 获取最近更新书籍
const [latelyList, setLatelyList] = useData([])
const getLatelyList = async () => {
  const data = await getLately()
  setLatelyList(data.data)
}
// 跳转详情页
function detaisGo(info,id) {
  store.commit('setBook',info)
  router.push({
    path: "/detail",
    query: {
      id: id
    }
  });
}
</script>

<style lang="scss" scoped>
@import '@/assets/style.css';
.header {
    background-color: #f6f7f9;
    height: 200px;
    width: 100%;
}
.logo {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    font-family: "SourceHanSerifCN-Bold";
    padding-top: 30px;
    img {
        width: 40px;
        height: 40px;
        margin-right: 10px;
    }
    h1 {
        font-family: "SourceHanSerifCN-Bold";
        font-size: 26px;
        font-weight: 400;
    }
}
.search-input {
    width: 80%;
    max-width: 840px;
    margin: 40px auto 0;
    position: relative;
    &:before {
        font-family: "iconfont";
        content: "\e614";
        position: absolute;
        top: 50%;
        left: 20px;
        width: 20px;
        height: 20px;
        z-index: 1;
        color: #999;
        font-size: 20px;
        transform: translateY(-50%);
    }
    input {
        border: none;
        box-shadow: 0 4px 20px rgba(0,25,104,.05);
        width: 100%;
        padding: 0 52px;
        height: 52px;
        font-size: 16px;
        box-sizing: border-box;
        border-radius: 26px;
    }
}
</style>
