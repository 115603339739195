import { createRouter, createWebHashHistory } from 'vue-router'
import store from '../store/index'

import home from '../views/home.vue'
import detail from '../views/detail.vue'
import list from '../views/list.vue'
const routes = [
  {
      path: '/',
      name: 'home',
      component: home,
      meta: { title: '盏茶阅读'}
  }, {
      path: '/detail',
      name: 'detail',
      component: detail,
      mata: { title: '' }
  }, {
      path: '/list',
      name: 'list',
      component: list
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if(to.name=='detail') {
    window.document.title = store.state.bookInfo.title+'/'+store.state.bookInfo.author+'-盏茶阅读'
  } else if(to.name=='list') {
    window.document.title = store.state.searchDetails+'-盏茶阅读'
  } else {
    window.document.title = to.meta.title
  }
  next()
})

export default router
